<template>
  <v-row class="flex-column">
    <v-col>
      <h3 class="mb-2">2. Subir archivo</h3>

      <schedule-form
        v-show="!uploadedFileInQueue || !uploadingNow"
        :form-data="scheduleFormData"
        class="mb-4"
        @formChecked="handleDropzoneStatus($event)"
      />

      <v-alert v-model="uploadedFileError" type="error" text dismissible>
        {{ errorMessage }}
      </v-alert>

      <vue-dropzone
        v-show="initialCheck && !uploadedFileInQueue && !uploadingNow"
        ref="dropzoneComp"
        id="dropzone"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        @vdropzone-error="errorDropzoneHandle"
        @vdropzone-success="successDropzoneHandle"
        @vdropzone-sending="sendingFile"
      >
        <div class="text-center mx-5 mx-sm-10">
          <span class="text-subtitle-1 font-weight-bold">
            Arrastra el archivo excel aquí, o haz clic para buscarlo en tu dispositivo
          </span>
          <br />
          <span>Formato permitido: {{ dropzoneOptions.acceptedFiles }}</span>
          <br />
          <span>Límite de peso: {{ dropzoneOptions.maxFilesize }}MB</span>
        </div>
      </vue-dropzone>

      <uploading-card v-if="uploadingNow || !initialCheck"></uploading-card>
      <success-card v-else-if="uploadedFileInQueue"></success-card>
    </v-col>

    <v-col v-if="pendingImports && pendingImports.length">
      <h4 class="mb-2">Importaciones programadas</h4>
      <pending-imports-list :pending-imports="pendingImports"></pending-imports-list>
    </v-col>
  </v-row>
</template>

<script>
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Cookies from "js-cookie";

export default {
  components: {
    vueDropzone: () => import("vue2-dropzone"),
    scheduleForm: () => import("@/components/crud-md/actions/import-items/step-two/ScheduleForm"),
    successCard: () => import("@/components/crud-md/actions/import-items/step-two/SuccessCard"),
    uploadingCard: () => import("@/components/crud-md/actions/import-items/step-two/UploadingCard"),
    pendingImportsList: () =>
      import("@/components/crud-md/actions/import-items/step-two/PendingImportsList"),
  },
  props: {
    importDialog: { type: Boolean },
    getUploadingStatusAction: { type: Function, required: true },
    getImportPath: { type: Function, required: true },
  },
  data: () => ({
    initialCheck: null,
    uploadedFileInQueue: null,
    uploadedFileError: null,
    uploadingNow: null,
    dropzoneOptions: {
      url: null,
      maxFilesize: 10,
      acceptedFiles: ".xls, .xlsx",
      maxFiles: 1,
      headers: {
        "Http-Authorization": `Bearer ${Cookies.get("auth_token")}`,
      },
    },
    errorMessage: null,
    defaultErrorMessage:
      "Ha habido un error al subir el archivo, comprueba que el excel tiene el formato correcto yvuelve a intentarlo. Si el error persiste, por favor, contacta con el administrador del sistema.",
    pendingImports: [],
    scheduleFormData: {
      scheduleDate: null,
      name: null,
    },
  }),
  beforeMount() {
    this.errorMessage = this.defaultErrorMessage;
    this.dropzoneOptions.url = this.getImportPath();
  },
  mounted() {
    this.checkUploadingNow();
  },
  methods: {
    errorDropzoneHandle() {
      this.uploadedFileInQueue = null;
      this.errorMessage = this.defaultErrorMessage;
      this.uploadedFileError = true;
      this.$refs.dropzoneComp.removeAllFiles();
    },
    sendingFile(file, xhr, formData) {
      const { name, scheduleDate } = this.scheduleFormData;
      !!name && formData.append("name", name);
      !!scheduleDate && formData.append("scheduleDate", scheduleDate);
    },
    async successDropzoneHandle(file, response) {
      if (this.uploadedFileError) this.uploadedFileError = null;
      this.errorMessage = this.defaultErrorMessage;
      if (response.errorMessage) {
        this.errorMessage = response.errorMessage;
        this.uploadedFileError = true;
      } else {
        await this.checkUploadingNow();
        if (this.uploadingNow) {
          this.uploadedFileInQueue = true;
        } else {
          this.$emit("uploadedFile");
        }
      }
      this.$refs.dropzoneComp.removeAllFiles();
    },
    async checkUploadingNow() {
      try {
        const { data } = await this.getUploadingStatusAction();
        this.uploadingNow = data.uploading;
        this.pendingImports = data.pendingImports;
        this.initialCheck = true;
      } catch {
        this.$notifyError(
          "Error al inicializar el componente",
          "La comprobación del estado de importación ha fallado. Si este error persiste, por favor, contacta cn el administrador del sistema."
        );
        this.uploadingNow = false;
      }
    },
    handleDropzoneStatus(canUpload) {
      if (!this.$refs.dropzoneComp) return;
      canUpload ? this.$refs.dropzoneComp.enable() : this.$refs.dropzoneComp.disable();
    },
  },
  watch: {
    importDialog(newValue) {
      if (!newValue) {
        this.$refs.dropzoneComp.removeAllFiles();
        this.uploadedFileError = null;
        this.uploadedFileInQueue = null;
      }
    },
  },
};
</script>

<style scoped></style>
